<template>
    <div>
        <!-- Filters -->
        <!-- <play-list-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchGroupContest" /> -->
        <b-card>
            <b-row class="justify-content-center my-3">
                <!-- <b-col cols="6">
                    <b-row class="justify-content-center">
                        <h4>Choose a contest</h4>
                    </b-row>
                    <v-select v-model="contest_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="contestsOption" :clearable="false" label="name" input-id="city" @input="fetchStageList(contest_id)" />
                </b-col> -->
                <b-col cols="8">
                    <b-row class="justify-content-center">
                        <h4>Choose a stage</h4>
                    </b-row>
                    <v-select v-model="stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="stagesOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                </b-col>
            </b-row>
        </b-card>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0" v-if="stage_id != null">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePlaylist({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative pb-3" :items="contests" :per-page="perPage" responsive :fields="groupTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Music -->
                <template #cell(name)="data">
                    <b-media vertical-align="center">
                        <template #aside> </template>
                        <b-link :to="{ name: 'apps-genres-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                            {{ data.item.name }}
                        </b-link>
                        <small v-if="data.item.name_ar" class="text-muted">{{ data.item.name_ar }}</small>
                    </b-media>
                </template>
                <template #cell(created_at)="data">
                    <b-badge variant="light-danger"> {{ data.item.created_at }} </b-badge>
                </template>
                <template #cell(is_active)="data">
                    <b-badge variant="light-success" v-if="data.item.is_active == 1"> Active </b-badge>
                    <b-badge variant="light-danger" v-else> Not active </b-badge>
                </template>
                <template #cell(is_published)="data">
                    <b-badge variant="light-success" v-if="data.item.is_published == 1"> Published </b-badge>
                    <b-badge variant="light-danger" v-else> Not published </b-badge>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item
                            :to="{
                                name: 'apps-contest-group-participants',
                                params: { groupId: data.item.id, contestId: currentRoutId },
                            }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Participants list</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{
                                name: 'apps-contest-group-edit',
                                params: { contestId: currentRoutId, groupId: data.item.id },
                            }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteContest(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePlaylist({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div> -->
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import PlayListListFilters from "./PlayListListFilters.vue";
import userStoreModule from "../../user/userStoreModule";
import genresRepository from "@/abstraction/repository/genresRepository";
import useContestList from "../useContestList";
import ContestRepository from "@/abstraction/repository/contestRepository";
const contestRepository = new ContestRepository();
const genreRepository = new genresRepository();
export default {
    components: {
        PlayListListFilters,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const {
            perPageOptions,
            contests,
            pagination,
            perPage,
            sortBy,
            tableColumns,
            currentPage,
            fetchGroupContest,
            groupTableColumns,
            deleteContest,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useContestList();
        const contestsOption = ref([]);
        const contest_id = ref();
        const stage_id = ref();
        const stagesOption = ref();
        const currentRoutId = ref(router.currentRoute.params.contestId);
        contestRepository.index().then((res) => {
            console.log(res);
            contestsOption.value = res.data;
        });
        const fetchStageList = () => {
            contestRepository.fetchStageContest(router.currentRoute.params.contestId).then((res) => {
                console.log(res);
                stagesOption.value = res.data;
            });
        };
        fetchStageList();
        const USER_APP_STORE_MODULE_NAME = "app-user";
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const fetchGroupHandler = (id) => {
            console.log(id);
            fetchGroupContest(id).then((res) => {
                res.value.map((item) => {
                    return item.created_at.substring(0, 10);
                });
                for (let i = 0; i < res.value.length; i++) {
                    const element = res.value[i];
                    console.log(element);
                    element.created_at = element.created_at.substring(0, 10);
                }
            });
        };
        onMounted(() => {});

        const changePagePlaylist = async (paginate) => {
            await fetchGroupContest(paginate);
        };

        const updateSort = async (id) => {
            await genreRepository.updateSort(id);
            await fetchGroupContest({ page: currentPage, perPage: perPage });
        };

        return {
            contests,
            pagination,
            // Filter
            avatarText,
            perPageOptions,
            changePagePlaylist,
            fetchGroupContest,
            perPage,
            currentPage,
            sortBy,
            tableColumns,
            deleteContest,
            contestsOption,
            contest_id,
            fetchGroupHandler,
            stage_id,
            stagesOption,
            fetchStageList,
            currentRoutId,
            groupTableColumns,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            updateSort,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
